.navigationMenu {
    height: 100%;
    width: 330px;
    position: fixed;
    z-index: 1;
    top: 90px;
    left: 0;
    background-color: #B0CDDC;
    overflow-x: hidden;
    padding-top: 20px;
}

    .navigationMenu ul li {
        list-style: none;
    }

        .navigationMenu ul li a {
            display: flex;
        }

.quote_header {
    text-align: start;
    margin-top: 10px;
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
}

.nav_bar {
    background-color: #003550;
    width: 10px;
    position: absolute;
    left: 42px;
    top: 105px;
    max-height: 70px;
}

.progress-done {
    background-color: #003550;
    width: 10px;
    height: 0px;
    max-height: 70px;
    position: absolute;
    left: 42px;
    top: 130px;
    transition: 8s ease 0.1s;
}
.nav_bar{
    top:105px;
}
.property_bar {
    top: 190px;
}

.coverages_bar { 
    top: 270px;
}

.add_info_bar {
    top: 355px;
}

/*.nextstep_bar {
    top: 440px;
}*/

.inActive {
    display: none;
}


.link {
    text-decoration: none;
}

.nav_option {
    position: relative;
    height: 70px;
    white-space: normal;
    color: #003550;
    font-size: 20px;
    text-decoration: none;
    margin-left: 10px;
}

    .nav_option:hover {
        text-decoration: none;
        font-weight: bold;
    }

.text_bold {
    font-weight: bold;
}

.nav_icon {
    position: relative;
    display: block;
    color: #003550;
    min-width: 30px;
    height: 30px;
    font-size: 4vmin;
    margin-bottom: 15px;
    z-index: 9999;
}

.icon_small {
    position: relative;
    color: #003550;
    min-width: 20px;
    height: 20px;
    font-size: 4vmin;
    margin-left: 25px;
    margin-bottom: 2px;
}

a:link {
    text-decoration: none;
}

@media only screen and (max-width:1024px) {

    .navigationMenu {
        display: none;
    }

    .info_icon {
        display: none;
    }
}
