.retrieve_list_offset {
    padding-top: 100px;
}

.modal_settings_list {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 800px;
    min-height: 150px;
    background-color: #FFF;
    padding: 50px;
    z-index: 1000;
    border-radius: 15px;
    padding-bottom: 25px;
}
.overlay_list {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.7);
    z-index: 1000;
}

.active_link_list {
    text-decoration: underline;
}

.invalid_list {
    width: 95%;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 20px;
    border: 1px solid red !important;
}

.valid_list {
    width: 95%;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 20px;
    border: 1px solid rgb(13, 145, 24) !important;
}

.error_list_txt {
    color: red;
    display: block;
    margin-left: 5px;
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
}

.retrieve_list_box {
    width: 60%;
    height: 100%;
    min-height:550px;
    align-content: center;
    padding: 20px;
    margin: 0px auto;
/*    background-image: url("/images/RetrieveQuote_Icon__09.jpg");
    background-position: right;
    background-position-x: 80px;
    background-size: cover;
    background-repeat: no-repeat;*/
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
}

.retrieve_list_title {
    display: block;
    color: #003550;
    font-size: 18px;
    text-align: center;
    padding-top: 10px;
}

.retrieve_list_label {
    display: block;
    color: #003550;
    font-size: 20px;
    margin: 0px auto;
    padding-top: 10px;
    padding-bottom: 2px;
    padding-left: 4px;
}

.bol_list {
    font-weight: bold;
}

.smal_list {
    font-size: 15px;
}

.inp_retrieve_list {
    width: 250px;
    max-width: 470px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 10px;
}

.inp_small_list {
    width: 150px;
    max-width: 470px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 10px;
}

.retrieve_list_button {
    display: block;
    width: 200px;
    height: 40px;
    font-size: 20px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 20px;
}
    .retrieve_list_button:hover {
        background-color: #002E5D;
        color: white;
    }

.continue_list_button {
    width: 80px;
    height: 30px;
    font-size: 15px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
}

    .continue_list_button:hover {
        background-color: #002E5D;
        color: white;
    }

@media only screen and (max-width:1024px) {

    .retrieve_list_box {
        width: 80%;
    }

    .retrieve_list_title {
        font-size: 23px;
        margin-right: 0;
        margin-left: 70px;
        text-align: left;
    }
}

@media only screen and (max-width:768px) {

    .retrieve_list_box {
        width: 100%;
    }

    .retrieve_title {
        font-size: 23px;
        margin-right: 0;
        margin-left: 0px;
        text-align: left;
    }
}
@media only screen and (max-width:430px) {
    .tbody_retrieve_a2 td {
        padding: 0.25rem;
    }
    .retrieve_list_box {
        margin-bottom: 10px;
    }

    .retrieve_list_title {
        margin: 0px auto;
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .retrieve_list_label {
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }
}
