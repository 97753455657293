.p5_title {
    display: block;
    color: #003550;
    font-size: 27px;
}
.p5_2_title {
    display: block;
    color: #003550;
    font-size: 27px;
}

.p4_radio_button {
    margin-left: 40px;
    width: 20px;
    height: 20px;
}

.btn_p6 {
    display: block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: right;
    margin-right: 10px;
    margin-top: 50px;
}
    .btn_p6:hover {
        background-color: #002E5D;
        color: white;
    }
.btn_p6_2 {
    display: block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: left;
    margin-right: 10px;
    margin-top: 50px;
}

    .btn_p6_2:hover {
        background-color: #002E5D;
        color: white;
    }

.vDivider_p6 {
    display: none;
}

.quote_name {
    text-align: start;
    margin-top: 10px;
    margin-left: 30px;
    font-size: 20px;
    font-weight: bold;
}

.p6_input {
    width: 220px;
    max-width: 470px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 25px;
    margin-bottom: 10px;
    margin-left:35px;
}

.p5_label_normal {
    display: block;
    color: #003550;
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}

.p5_label_hide {
    display: block;
    color: #003550;
    font-size: 20px;
    margin-left: 15px;
}

.radio_button_text {
    color: #003550;
    font-size: 25px;
    padding-right: 30px;
    padding-left: 5px;
}

.contentBox_p5 {
    display: grid;
    min-height: 400px;
    max-width: 100%;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    grid-template-areas:
        "p5_c1_r0 p5_c2_r0"
        "p5_c1_r1 p5_c2_r1"
        "p5_c1_r2 p5_c2_r2"
        "p5_c1_r3 p5_c2_r3"
        "p5_c1_r4 p5_c2_r4"
        "p5_c1_r5 p5_c2_r5"
        "p5_c1_r6 p5_c2_r6"
        "p5_c1_r7 p5_c2_r7"
        "p5_c1_r8 p5_c2_r8"
        "p5_c1_r9 p5_c2_r9"
        "p5_c1_r10 p5_c2_r10"
        "p5_c1_r11 p5_c2_r11"
        "p5_c1_r12 p5_c2_r12"
        "p5_c1_r13 p5_c2_r13"
        "p5_c1_r14 p5_c2_r14"
        "p5_c1_r15 p5_c2_r15"
        "p5_c1_r16 p5_c2_r16"
        "p5_c1_r17 p5_c2_r17"
        "p5_c1_r18 p5_c2_r18"
        "p5_c1_r19 p5_c2_r19"
        "p5_c1_r20 p5_c2_r20"
        "p5_c1_r21 p5_c2_r21"
        "p5_c1_r22 p5_c2_r22"
        "p5_c1_r23 p5_c2_r23"
        "footer_p5_1 footer_p5_1"
        "footer_p5_2 footer_p5_2";
    grid-gap: 0rem;
    grid-column-gap: 2rem;
    padding: 20px 20px 0px 20px;
    grid-area: contentBox_p4;
    margin-left: 120px;
    margin-bottom: 20px;
}
.p5_c1_r0 {
    /*  background-color: aqua;*/
    grid-area: p5_c1_r0;
}
.p5_c2_r0 {
    /*  background-color: aqua;*/
    grid-area: p5_c2_r0;
}

.p5_c1_r1 {
    /*  background-color: aqua;*/
    grid-area: p5_c1_r1;
}

.p5_c2_r1 {
    /* background-color: darkcyan;*/
    grid-area: p5_c2_r1;
}


.p5_c1_r2 {
    /*   background-color: darkkhaki;*/
    grid-area: p5_c1_r2;
}

.p5_c2_r2 {
    /*  background-color: aqua;*/
    grid-area: p5_c2_r2;
}

.p5_c1_r3 {
    /*  background-color: darkkhaki;*/
    grid-area: p5_c1_r3;
}

.p5_c2_r3 {
    /*  background-color: darkcyan;*/
    grid-area: p5_c2_r3;
}

.p5_c1_r4 {
    /* background-color: aqua;*/
    grid-area: p5_c1_r4;
}

.p5_c2_r4 {
    /*   background-color: darkmagenta;*/
    grid-area: p5_c2_r4;
}

.p5_c1_r5 {
    /* background-color: deepskyblue;*/
    grid-area: p5_c1_r5;
}

.p5_c2_r5 {
    /* background-color: darkkhaki;*/
    grid-area: p5_c2_r5;
}

.p5_c1_r6 {
    /*   background-color: darkcyan;*/
    grid-area: p5_c1_r6;
}

.p5_c2_r6 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r6;
}

.p5_c1_r7 {
    /*  background-color: deepskyblue;*/
    grid-area: p5_c1_r7;
}

.p5_c2_r7 {
    /* background-color: darkmagenta;*/
    grid-area: p5_c2_r7;
}

.p5_c1_r8 {
    /* background-color: darkkhaki;*/
    grid-area: p5_c1_r8;
}

.p5_c2_r8 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r8;
}

.p5_c1_r9 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r9;
}

.p5_c2_r9 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r9;
}

.p5_c1_r10 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r10;
}

.p5_c2_r10 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r10;
}

.p5_c1_r11 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r11;
}

.p5_c2_r11 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r11;
}


.p5_c1_r12 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r12;
}

.p5_c2_r12 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r12;
}



.p5_c1_r13 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r13;
}

.p5_c2_r13 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r13;
}



.p5_c1_r14 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r14;
}

.p5_c2_r14 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r14;
}



.p5_c1_r15 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r15;
}

.p5_c2_r15 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r15;
}


.p5_c1_r16 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r16;
}

.p5_c2_r16 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r16;
}


.p5_c1_r17 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r17;
}

.p5_c2_r17 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r17;
}

.p5_c1_r18 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r18;
}

.p5_c2_r18 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r18;
}

.p5_c1_r19 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r19;
}

.p5_c2_r19 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r19;
}

.p5_c1_r20 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r20;
}

.p5_c2_r20 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r20;
}

.p5_c1_r21 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r21;
}

.p5_c2_r21 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r21;
}
.p5_c1_r22 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r22;
}

.p5_c2_r22 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r22;
}
.p5_c1_r23 {
    /*    background-color: aqua;*/
    grid-area: p5_c1_r23;
}

.p5_c2_r23 {
    /*    background-color: aqua;*/
    grid-area: p5_c2_r23;
}

.footer_p5_1 {
    grid-area: footer_p5_1;
    position: relative;
    text-align: center;
}
.footer_p5_1a {
    width: 230px;
    height: 70px;
    font-size: 25px;
    background-color: #003550;
    color: white;
    border-radius: 10px;
    text-decoration: none;
}

    .footer_p5_1a:hover {
        background-color: #286584;
        color: white;
        text-decoration: none;
        border: 0;
    }
.footer_p5_1b {
    position: absolute;
    left: 20px;
    bottom: 0;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-decoration: underline;
}
.footer_p5_1b span {
    text-decoration: underline;
}
/*.footer_p5_1b {
    grid-area: footer_p5_1b;
}*/
.footer_p5_2 {
    grid-area: footer_p5_2;
}

.p5_c1_r2_inActive {
    display: none;
}

.p5_c2_r2_inActive {
    display: none;
}
.additionalinfo_box_wrapper {
    position: absolute;
    left: 328px;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 20px;
}

.contentBox_p5 {
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.p5_title {
    max-width: 1000px;
    text-align: left;
}
.p5_2_title {
    max-width: 1100px;
    text-align: left;
    margin-right: 485px;
}
@media only screen and (max-width:1024px) {
    .contentBox_p5 {
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
    }

    .p5_title {
        font-size: 20px;
        margin-left: 9px;
    }
    .p5_2_title {
        font-size: 20px;
        margin-left: 20px;
        width: 100%;
        margin-right: 0;
    }
    .additionalinfo_box_wrapper {
        position: unset;
        width: 100%;
        padding-right: 0;

    }
}

@media only screen and (max-width:768px) {
    .contentBox_p5 {
        grid-template-columns: 2fr 1fr;
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
    }

    .p5_title {
        font-size: 17px;
    }
    .p5_2_title {
        font-size: 17px;
    }
    .footer_p5_1a{
        width: 60%;
    }
    .footer_p5_1b{
        left: 0;
    }
}

@media only screen and (max-width:414px) {
    .contentBox_p5 {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "p5_c1_r0"
            "p5_c2_r0"
            "p5_c1_r1"
            "p5_c2_r1"
            "p5_c1_r2"
            "p5_c2_r2"
            "p5_c1_r3"
            "p5_c2_r3"
            "p5_c1_r4"
            "p5_c2_r4"
            "p5_c1_r5"
            "p5_c2_r5"
            "p5_c1_r6"
            "p5_c2_r6"
            "p5_c1_r7"
            "p5_c2_r7"
            "p5_c1_r8"
            "p5_c2_r8"
            "p5_c1_r9"
            "p5_c2_r9"
            "p5_c1_r10"
            "p5_c2_r10"
            "p5_c1_r11"
            "p5_c2_r11"
            "p5_c1_r12"
            "p5_c2_r12"
            "p5_c1_r13"
            "p5_c2_r13"
            "p5_c1_r14"
            "p5_c2_r14"
            "p5_c1_r15"
            "p5_c2_r15"
            "p5_c1_r16"
            "p5_c2_r16"
            "p5_c1_r17"
            "p5_c2_r17"
            "p5_c1_r18"
            "p5_c2_r18"
            "p5_c1_r19"
            "p5_c2_r19"
            "p5_c1_r20"
            "p5_c2_r20"
            "p5_c1_r21"
            "p5_c2_r21"
            "p5_c1_r22"
            "p5_c2_r22"
            "p5_c1_r23"
            "p5_c2_r23"
            "footer_p5_1"
            "footer_p5_2"
            "footer_p5_2";
        max-width: 100%;
        box-shadow: 0px 0px 10px 2px grey;
        border-radius: 15px;
        grid-gap: 0px;
        width: 100%;
        padding: 20px 10px 0 10px;
    }

   

    .p5_title {
        font-size: 15px;
    }
    .p5_2_title {
        font-size: 15px;
    }

    .vDivider_p6 {
        display: block;
        width: 98%;
        height: 5px;
        margin: 20px auto;
        border-bottom: 3px solid #003550;
    }

    .thin {
        border-bottom: 1px solid #003550;
        width: 70%;
    }

    .p4_radio_button {
        margin-left: 40px;
        width: 20px;
        height: 20px;
    }

    .radio_button_text {
        font-size: 30px;
    }

    .btn_p6 {
        width: 100%;
        margin: 0 auto;
    }
    .btn_p6_2 {
        width: 100%;
        margin-top: 20px;
    }

    .p5_label_hide {
        margin: 0 auto;
    }

    .p5_label_normal {
        font-size: 20px;
    }

    .light {
        font-weight: normal;
    }

    .p6_input {
        width: 90%;
        margin-top: 10px;
        margin-left: 15px;
    }
    .footer_p5_1a{
        margin-left: 10px;
    }


}

.footer_p6_1 {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #003550;
    margin-block-end: 0px;
    padding-top:40px;
}
.footer_p6_1_5 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
    margin-block-end: 0px;
   
}
    .footer_p6_1_5 a {
        color: #286584;
    }

.footer_p6_2 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
}