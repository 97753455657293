

.info_icon {
    width: 20px;
    height: 20px;
    background-image: url("/images/tooltip_icon.png");
    background-size: cover;
    background-position: center;
    position: relative;
    display: inline-flex;
    margin-left: 15px;
    padding-top: 10px;
}

    .info_icon::before,
    .info_icon::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        top: -.25rem;
        left: 50%;
        transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: bottom center;
    }

    .info_icon::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 300px;
        background: var(--tooltip-color);
    }

    .info_icon:hover::before,
    .info_icon:hover::after {
        --scale: 1;
    }

    .info_icon::after {
        --translate-y: calc(-1 * var(--arrow-size));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-top-color: var(--tooltip-color);
        transform-origin: top center;
    }

.wrapper {
    display: grid;
    justify-content: center;
    padding-top: 90px;
    padding-right: 5px;
    max-height: 1100px;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.05fr 0.2fr 0.05fr 0.2fr 0.1fr;
    grid-template-areas:
        "baner_p5"
        "title_1"
        "contentBox_p5_1"
        "title_3"
        "contentBox_p5_3"
        "footer_p5";
    grid-gap: 1rem;
}

.footer_p5 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.1fr;
    grid-template-areas:
        "footer_p5_1"
        "footer_p5_2";
    grid-area: footer_p5;
    margin-left: 120px;
}

.footer_p5_1 {
    grid-area: footer_p5_1;
    position: relative;
    text-align: center;
}

.footer_p5_2 {
    grid-area: footer_p5_2;
}

.title_1 {
    grid-area: title_1;
    margin-left: 120px;
    display: flex;
    align-items: center;
}

.title_2 {
    grid-area: title_2;
    margin-left: 120px;
}

.title_3 {
    grid-area: title_3;
    margin-left: 120px;
}

.contentBox_p5_1 {
    display: grid;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    padding: 25px;
    grid-template-columns: 2.5fr 1fr 1fr;
    grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr;
    grid-template-areas:
        "p5_c1_r1 p5_c1_r1 p5_c2_r1"
        "p5_c1_r2 p5_c1_r2 p5_c2_r2"
        "p5_c1_r3 p5_c1_r3 p5_c2_r3"
        "p5_c1_r4 p5_c1_r4 p5_c2_r4"
        "p5_c1_r5 p5_c1_r5 p5_c2_r5"
        "p5_c1_r6 p5_c1_r6 p5_c2_r6"
        "p5_c1_r7 p5_c1_r7 p5_c2_r7"
        "p5_c1_r8 p5_c1_r8 p5_c2_r8"
        "p5_c1_r9 p5_c1_r9 p5_c2_r9"
        "p5_c1_r10 p5_c1_r10 p5_c2_r10"
        "p5_c1_r10_5 p5_c1_r10_5 p5_c1_r10_5"
        "p5_c1_r11 p5_c1_r11 p5_c2_r11"
        "p5_c1_r12 p5_c1_r12 p5_c2_r12"
        "p5_c1_r13 p5_c1_r13 p5_c1_r13"
        "p5_c1_r14 p5_c1_r18 p5_c1_r18"
        "p5_c1_r15 p5_c1_r19 p5_c1_r19"
        "p5_c1_r16 p5_c1_r20 p5_c1_r20"
        "p5_c1_r17 p5_c1_r21 p5_c1_r21";
    grid-gap: 0.8rem;
    grid-area: contentBox_p5_1;
    margin-left: 120px;
}

.p5_c1_r1 {
    grid-area: p5_c1_r1;
}

.p5_c2_r1 {
    grid-area: p5_c2_r1;
}

.p5_c1_r2 {
    grid-area: p5_c1_r2;
}

.p5_c2_r2 {
    grid-area: p5_c2_r2;
}

.p5_c1_r3 {
    grid-area: p5_c1_r3;
}

.p5_c2_r3 {
    grid-area: p5_c2_r3;
}

.p5_c1_r4 {
    grid-area: p5_c1_r4;
}

.p5_c2_r4 {
    grid-area: p5_c2_r4;
}

.p5_c1_r5 {
    grid-area: p5_c1_r5;
}

.p5_c2_r5 {
    grid-area: p5_c2_r5;
}

.p5_c1_r6 {
    grid-area: p5_c1_r6;
}

.p5_c2_r6 {
    grid-area: p5_c2_r6;
}

.p5_c1_r7 {
    grid-area: p5_c1_r7;
}

.p5_c2_r7 {
    grid-area: p5_c2_r7;
}

.p5_c1_r8 {
    grid-area: p5_c1_r8;
}

.p5_c2_r8 {
    grid-area: p5_c2_r8;
}

.p5_c1_r9 {
    grid-area: p5_c1_r9;
}

.p5_c2_r9 {
    grid-area: p5_c2_r9;
}

.p5_c1_r10 {
    grid-area: p5_c1_r10;
}

.p5_c2_r10 {
    grid-area: p5_c2_r10;
}

.p5_c1_r11 {
    grid-area: p5_c1_r11;
}

.p5_c2_r11 {
    grid-area: p5_c2_r11;
}

.p5_c1_r12 {
    grid-area: p5_c1_r12;
}

.p5_c2_r12 {
    grid-area: p5_c2_r12;
}

.p5_c1_r13 {
    padding-top: 10px;
    grid-area: p5_c1_r13;
}

.p5_c2_r13 {
    grid-area: p5_c2_r13;
}

.p5_c1_r14 {
    grid-area: p5_c1_r14;
}

.p5_c2_r14 {
    grid-area: p5_c2_r14;
}

.p5_c1_r15 {
    grid-area: p5_c1_r15;
}

.p5_c2_r15 {
    grid-area: p5_c2_r15;
}

.p5_c1_r16 {
    grid-area: p5_c1_r16;
}

.p5_c2_r16 {
    grid-area: p5_c2_r16;
}


.p5_c1_r17 {
    grid-area: p5_c1_r17;
}

.p5_c2_r17 {
    grid-area: p5_c2_r17;
}


.p5_c1_r18 {
    grid-area: p5_c1_r18;
}

.p5_c2_r18 {
    grid-area: p5_c2_r18;
}


.p5_c1_r19 {
    grid-area: p5_c1_r19;
}

.p5_c2_r19 {
    grid-area: p5_c2_r19;
}


.p5_c1_r20 {
    grid-area: p5_c1_r20;
}

.p5_c2_r20 {
    grid-area: p5_c2_r20;
}


.p5_c1_r21 {
    grid-area: p5_c1_r21;
}

.p5_c2_r21 {
    grid-area: p5_c2_r21;
}

.p5_c1_r10_5 {
    grid-area: p5_c1_r10_5;
}




.contentBox_p5_2 {
    display: grid;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    padding: 25px;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr;
    grid-template-areas:
        "p5_c1_r1_2"
        "p5_c1_r2_2"
        "p5_c1_r3_2"
        "p5_c1_r4_2"
        "p5_c1_r5_2"
        "p5_c1_r6_2"
        "p5_c1_r7_2"
        "p5_c1_r8_2";
    grid-gap: 1rem;
    grid-area: contentBox_p5_2;
    margin-left: 120px;
}

.p5_c1_r1_2 {
    grid-area: p5_c1_r1_2;
}

.p5_c1_r2_2 {
    grid-area: p5_c1_r2_2;
}

.p5_c1_r3_2 {
    grid-area: p5_c1_r3_2;
}

.p5_c1_r4_2 {
    grid-area: p5_c1_r4_2;
}

.p5_c1_r5_2 {
    grid-area: p5_c1_r5_2;
}

.contentBox_p5_3 {
    display: grid;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    padding: 25px;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr;
    grid-template-areas:
        "p5_c1_r1_3"
        "p5_c1_r2_3"
        "p5_c1_r3_3"
        "p5_c1_r4_3";
    grid-gap: 1rem;
    grid-area: contentBox_p5_3;
    margin-left: 120px;
}

.p5_c1_r1_3 {
    grid-area: p5_c1_r1_3;
}

.p5_c1_r2_3 {
    grid-area: p5_c1_r2_3;
}

.p5_c1_r3_3 {
    grid-area: p5_c1_r3_3;
}

.p5_c1_r4_3 {
    grid-area: p5_c1_r4_3;
}

.p5_c1_r5_3 {
    grid-area: p5_c1_r5_3;
}

.p5_c1_r6_3 {
    grid-area: p5_c1_r6_3;
}

.p5_c1_r7_3 {
    grid-area: p5_c1_r7_3;
}

.p5_c1_r8_3 {
    grid-area: p5_c1_r8_3;
}

.title_label_p5 {
    color: #003550;
    font-size: 40px;
    margin-bottom: 0;
    width: 100%;
}

.sub_title {
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 10px;
}


.baner_normal {
    text-align: center;
    color: #003550;
    font-size: 24px;
    margin-bottom: 0px;
}

.baner_small {
    color: black;
    font-size: 10px;
    color: #003550;
    text-align: center;
    line-height: 5%;
    font-weight: bold;
}

.baner_big {
    display: block;
    color: #003550;
    font-size: 75px;
    font-weight: bold;
    text-align: center;
    line-height: 80%;
    margin-bottom: 0px;
}

.baner_bold {
    display: inline;
    color: #003550;
    font-weight: bold;
    font-size: 30px;
}

.baner_normal_b {
    color: #E8EAF9;
    font-size: 18px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-top: 15px;
}

.baner_small_b {
    font-size: 17px;
    color: #E8EAF9;
    margin-left: 75px;
}

.baner_bold_b {
    color: #E8EAF9;
    font-weight: bold;
    font-size: 25px;
}

.p5_label {
    color: #003550;
    font-size: 20px;
    margin-block: 0 auto;
}

.bol {
    font-size: 20px;
    font-weight: bold;
}

.ita {
    font-size: 15px;
    font-style: italic;
}

.small {
    white-space: nowrap;
    font-size: 20px;
}

.big {
    font-size: 65px;
    text-align: center;
}

.med {
    font-size: 32px;
}

.cent {
    text-align: center;
}

.vDivider {
    display: block;
    width: 100%;
    height: 5px;
    margin: 20px auto;
    border-bottom: 3px solid #003550;
    margin-block: 0 auto;
}

.small {
    width: 70%;
}

.icon_p6 {
    float: right;
    padding-top: 23px;
    padding-right: 10px;
    cursor: pointer;
}

/*.btn_p5 {
    display: inline-block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: right;
    margin-right: 10px;
    margin-top: 40px;
}

    .btn_p5:hover {
        background-color: #002E5D;
        color: white;
    }


.btn_p5_2 {
    display: inline-block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: left;
    margin-right: 10px;
    margin-top: 40px;
}

    .btn_p5_2:hover {
        background-color: #002E5D;
        color: white;
    }*/
/*.title_1_button_wrapper {
    display: inline-flex; 
    align-items: center; 
    margin-left: 20px;
}*/
.title_1_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fa;
    font-size: 1.5rem;
    cursor: pointer;
    border: 1px solid #dee2e6;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 0;
    margin-left: 20px;
}

.arrow-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-top: 5px solid #002E5D;
    border-right: 5px solid #002E5D;
    transform: rotate(135deg);
    transition: transform 0.2s ease;
}

.rotate {
    transform: rotate(315deg);
}
/*.title_1_button {
    color: #00b4ff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #dee2e6;
    background: #f8f9fa;
    font-size: 35px;
    padding: 0;
    margin-left: 20px;
    cursor: pointer;
    padding-bottom: 5px;
}
    .title_1_button span {
        display: block;
        height: inherit;
    }

    .title_1_button:before {
        content: "";
        display: block;
        padding-top: 80%;
    }

    .title_1_button.isExpanded:before {
        padding-top: 100px;
    }*/
.btn_p5_wrapper {
    display: block;
    margin: 0 auto;
}
.btn_p5 {
    width: 230px;
    height: 70px;
    font-size: 25px;
    background-color: #003550;
    color: white;
    border-radius: 10px;
    text-decoration: none;
}

    .btn_p5:hover {
        background-color: #286584;
        color: white;
        text-decoration: none;
        border: 0;
    }
.link_p5 {
    text-decoration: none;
}
.btn_p5_2 {
    position: absolute;
    left: 20px;
    bottom: 0;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: black;
}

    .btn_p5_2 span {
        text-decoration: underline;
    }
.loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    z-index: 9999;
}
/*    .loader p:first-of-type {
        font-size: 15px;
    }

    .loader p:last-of-type {
        font-size: 30px;
    }
    .loader div:last-of-type {
        margin-top: 15px;
        text-align: center;
    }*/
.footer_1 {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #003550;
    margin-block-end: 0px;
    margin-top: 60px;
}

.footer_1_5 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #286584;
    margin-block-end: 0px;
}
    .footer_1_5 a {
        color: #286584;
    }

.footer_2 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
    padding-bottom: 20px;
}
.wrapper_header {
    position: absolute;
    left: 328px;
    width: 80%;
}
.wrapper {
    max-width: 1100px;
    margin: 0 auto;
}
@media only screen and (max-width:1200px) {
    .baner_p5 {
        margin-left: 0px;
    }
    .wrapper {
        width: 70%;
        margin: 0 auto;
    }
}
@media only screen and (max-width:1024px) {
    .wrapper_header {
        position: unset;
        width: auto;
    }
    .wrapper{
        width: 100%;
        margin: 0;
    }
}

@media only screen and (max-width:992px) {

   

    .baner_p5 {
        width: 100%;
        margin-left: 0px;
    }

    .contentBox_p5_1 {
        width: 100%;
        margin-left: 0px;
        grid-column-gap: 10px;
        padding-left: 20px;
        padding-right: 15px;
        grid-template-areas:
            "p5_c1_r1 p5_c1_r1 p5_c2_r1"
            "p5_c1_r2 p5_c1_r2 p5_c2_r2"
            "p5_c1_r3 p5_c1_r3 p5_c2_r3"
            "p5_c1_r4 p5_c1_r4 p5_c2_r4"
            "p5_c1_r5 p5_c1_r5 p5_c2_r5"
            "p5_c1_r6 p5_c1_r6 p5_c2_r6"
            "p5_c1_r7 p5_c1_r7 p5_c2_r7"
            "p5_c1_r8 p5_c1_r8 p5_c2_r8"
            "p5_c1_r9 p5_c1_r9 p5_c2_r9"
            "p5_c1_r10 p5_c1_r10 p5_c2_r10"
            "p5_c1_r10_5 p5_c1_r10_5 p5_c1_r10_5"
            "p5_c1_r11 p5_c1_r11 p5_c2_r11"
            "p5_c1_r12 p5_c1_r12 p5_c2_r12"
            "p5_c1_r13 p5_c1_r13 p5_c1_r13"
            "p5_c1_r14 p5_c1_r14 p5_c1_r14"
            "p5_c1_r15 p5_c1_r15 p5_c1_r15"
            "p5_c1_r16 p5_c1_r16 p5_c1_r16"
            "p5_c1_r17 p5_c1_r17 p5_c1_r17"
            "p5_c1_r18 p5_c1_r18 p5_c1_r18"
            "p5_c1_r19 p5_c1_r19 p5_c1_r19"
            "p5_c1_r20 p5_c1_r20 p5_c1_r20"
            "p5_c1_r21 p5_c1_r21 p5_c1_r21"
    }
    .p5_c1_r8 {
        margin-left: 20px;
    }
    .p5_c1_r9 {
        margin-left: 20px;
    }

    .contentBox_p5_2 {
        width: 100%;
        margin-left: 0px;
    }

    .contentBox_p5_3 {
        width: 100%;
        margin-left: 0px;
        height: 210px;
    }

    .baner_normal {
        font-size: 20px;
    }

    .baner_small {
        margin-top: 15px;
        font-size: 7px;
    }

    .baner_big {
        font-size: 75px;
    }

    .baner_bold {
        font-size: 22px;
    }

    .baner_normal_b {
        font-size: 18px;
    }

    .baner_small_b {
        font-size: 14px;
    }

    .baner_bold_b {
        font-size: 22px;
    }

    .info_icon {
        width: 15px;
        height: 15px;
        margin-right: 0;
        margin-left: 3px;
    }

        .info_icon::before,
        .info_icon::after {
            transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale)) ;
        }

        .info_icon::before {
            --translate-y: calc(-99% - var(--arrow-size));
            max-width: 100px;
            z-index: 999;
        }

    .title_1 {
        margin-left: 0;
    }

    .title_3 {
        margin-left: 0;
    }

    .title_label_p5 {
        margin-left: 5px;
    }
}

@media only screen and (max-width:758px) {
    .contentBox_p5_1 {
        grid-template-columns: 3fr 1fr;
    }

    .p5_label {
        font-size: 15px;
    }

    .sub_title {
        font-size: 15px;
    }

    .btn_p5 {
        width: 100%;
        float: none;
    }

    .title_label_p5 {
        font-size: 25px;
    }

    .footer_p5 {
        margin-left: 0;
    }

    .footer_p5_1 {
        align-content: center;
    }

    .footer_p5_2 {
        align-content: center;
    }

    .btn_p5 {
        width: 60%;
    }

    .btn_p5_2 {
        left: 0;
        margin-top: 20px;
    }
}


@media only screen and (max-width:440px) {
    .icon_p6 {
        display: none;
    }
    .wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }
    .info_icon::before,
    .info_icon::after {
        transform: translateX(-55%) translateY(var(--translate-y, 0)) scale(var(--scale));
    }

    .info_icon::before {
        --translate-y: calc(-99% - var(--arrow-size));
        max-width: 100px;
        z-index: 999;
    }
}
@media only screen and (max-width:413px) {
    .property_icon {
        margin-left: 3px;
    }
}

@media only screen and (max-width:360px) {
    .p5_label {
        font-size:13px;
    }
}