.retrieve_offset{
    padding-top:100px;
}

.modal_settings {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 800px;
    min-height: 150px;
    background-color: #FFF;
    padding: 50px;
    z-index: 1000;
    border-radius: 15px;
    padding-bottom: 25px;
}
.overlay{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,.7);
    z-index: 1000;
}

.active_link {
    text-decoration: underline;
}

.invalid {
    width: 95%;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 20px;
    border: 1px solid red !important;
}

.valid {
    width: 95%;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 20px;
    border: 1px solid rgb(13, 145, 24) !important;
}

.error_txt {
    color: red;
    display: block;
    margin-left: 5px;
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
}

.retrieve_box {
    width: 60%;
    height: 100%;
    min-height:550px;
    align-content: center;
    padding: 20px;
    margin: 0px auto;
    background-image: url("/images/RetrieveQuote_Icon__09.jpg");
    background-position: right;
    background-position-x: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
}

.retrieve_title {
    display: block;
    color: #003550;
    font-size: 30px;
    text-align: center;
    margin-right: 120px;
    padding-top: 10px;
}

.retrieve_label {
    display: block;
    color: #003550;
    font-size: 20px;
    margin: 0px auto;
    padding-top: 10px;
    padding-bottom: 2px;
    padding-left: 4px;
}

.bol {
    font-weight: bold;
}

.smal {
    font-size: 15px;
}

.inp_retrieve {
    width: 250px;
    max-width: 470px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 10px;
}

.inp_small {
    width: 150px;
    max-width: 470px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 10px;
}

.retrieve_button {
    display: block;
    width: 150px;
    height: 40px;
    font-size: 20px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 20px;
}
    .retrieve_button:hover {
        background-color: #002E5D;
        color: white;
    }

@media only screen and (max-width:1024px) {

    .retrieve_box {
        width: 80%;
    }

    .retrieve_title {
        font-size: 23px;
        margin-right: 0;
        margin-left: 70px;
        text-align: left;
    }
}

@media only screen and (max-width:768px) {

    .retrieve_box {
        width: 100%;
    }

    .retrieve_title {
        font-size: 23px;
        margin-right: 0;
        margin-left: 0px;
        text-align: left;
    }
}

@media only screen and (max-width:375px) {
    .retrieve_title {
        margin: 0px auto;
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .retrieve_label {
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }
}
