
.alert_box {
    display: block;
    width: 810px;
    height: 80px;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 12px;
    margin-left: 120px;
    padding: 20px;
    text-align: center;
}


.alert_message {
    font-size: 15px;
    font-weight: 500;
}

