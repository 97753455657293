.p2_icon{
  display:none;
}

.txt_p2_1a {
    color: #003550;
    font-size: 20px;
    padding-top: 120px;
}

.txt_p2_1b {
    color: #003550;
    font-size: 20px;
    padding-top: 0px;
}

.txt_p2_2 {
    color: #003550;
    text-align: left;
    font-size: 25px;
}

.txt_p2_3 {
    color: #003550;
    margin-left: 100px;
    font-weight: 400;
}
.bold_p2_3 {
    color: #003550;
    font-weight: bold;
}

.spc {
    width: 100%;
    height: 120px;
    box-shadow: 0px 0px 10px 2px grey;
    float: left;
    margin: 15px auto;
    margin-bottom: 0px;
    margin-right: 10px;
    border-radius: 15px;
    background-image: url("/images/page_2_hori_box.png");
    background-repeat: no-repeat;
    background-position: left;
}

.txt_p2_4 {
    position: absolute;
    color: #003550;
    margin-right: 4px;
    margin-top: 22px;
    font-weight: bold;
    opacity: 1;
}

.img_p2 {
    opacity: 0.2;
    background-repeat: no-repeat
}

.btn_p2 {
    width: 100%;
    height: 70px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 25px;
    position: relative;
}
    .btn_p2:hover {
        background-color: #5264be;
        color: white;
    }
.btn_p2_2 {
   
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 25px;
    position: relative;
    width: 160px;
    height: 65px;
    float: left;
    margin-right: 20px;
    margin-left: 0;
}

    .btn_p2_2:hover {
        background-color: #5264be;
        color: white;
    }


.nmb {
    position:absolute;
    color: white;
    font-size: 40px;
    margin-left: 20px;
    margin-top: 30px;
}

@media only screen and (min-width: 1200px) {

    .p2_icon {
        display:block;
        margin-left: 50px;
    }

    .txt_p2_3 {
        color: #003550;
        margin-left: 10px;
        margin-top: 12px;
        font-weight: 400;
    }

    .nmb {
        position: initial;
        color: white;
        font-size: 40px;
        margin-left: 90px;
        margin-top: 0px;
        top: 50px;
    }

    .spc {
        width: 210px;
        height: 400px;
        background-image: url("/images/page_2_ver_box.png");
    }

    .btn_p2 {
        width: 160px;
        height: 65px;
        float: right;
        margin-right: 20px;
        margin-left: 0;
    }
}
