.info_icon {
    width: 20px;
    height: 20px;
    background-image: url("/images/tooltip_icon.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    display: inline-flex;
    margin-left: 15px;
}

    .info_icon::before,
    .info_icon::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        top: -.25rem;
        left: 50%;
        transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: bottom center;
    }

    .info_icon::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 300px;
        background: var(--tooltip-color);
    }

    .info_icon:hover::before,
    .info_icon:hover::after {
        --scale: 1;
    }

    .info_icon::after {
        --translate-y: calc(-1 * var(--arrow-size));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-top-color: var(--tooltip-color);
        transform-origin: top center;
    }
.no_wrap{
    white-space: nowrap;
}
.home_value{
    display: contents;
}
/*.btn_p4 {
    display: block;
    width: 250px;
    height: 100px;
    font-size: 25px;
    background-color: #002E5D;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: right;
    margin-right: 10px;
    margin-top: 30px;
}
    .btn_p4:hover {
        background-color: #3D9BE9;
        color: white;
    }*/

/*.btn_p4_2 {
    display: block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: left;
    margin-right: 10px;
    margin-top: 30px;
}*/

/*    .btn_p4_2:hover {
        background-color: #002E5D;
        color: white;
    }*/
.loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    z-index: 9999;
}

/*    .loader p:first-of-type {
        font-size: 15px;
    }

    .loader p:last-of-type {
        font-size: 30px;
    }

    .loader div:last-of-type {
        margin-top: 15px;
        text-align: center;
    }*/

.loader_text_wrapper {
    display: flex;
    align-items: center;
    margin-top: 100px;
    text-align: left;
}
.loader_title{
    font-size: 25px;
    color: #003550;
}
.loader_text1 {
    font-size: 40px;
    margin-right: 50px;
    color: #003550;
}
.loader_text2 {
    font-size: 25px;
    color: #003550;
}
.btn_p4_wrapper {
    position: relative;
    text-align: center;
}
.btn_p4 {
    width: 230px;
    height: 70px;
    font-size: 25px;
    background-color: #003550;
    color: white;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
}
    .btn_p4:hover {
        background-color: #286584;
        color: white;
        border: 0;
    }
.btn_p4_2 {
    position: absolute;
    left: 20px;
    bottom: 0;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: black;
}
    .btn_p4_2 span{
    text-decoration: underline;
    }

.option {
    position: relative;
    height: 60px;
    white-space: normal;
    color: #003550;
    font-size: 2vmin;
    margin-top: 10px;
    margin-left: 10px;
}

.icon {
    position: relative;
    display: block;
    color: #003550;
    min-width: 60px;
    height: 60px;
    font-size: 4vmin;
    margin-bottom: 30px;
}

.quote_name {
    text-align: start;
    margin-top: 10px;
    margin-left: 30px;
    font-size: 20px;
    font-weight: bold;
}

.contentBox_p4 {
    display: grid;
    min-height: 600px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.1fr 0.1fr 0.7fr 0.7fr 0.5fr 0.5fr;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    grid-template-areas:
        "p4_c1_r0 p4_c2_r0 p4_c3_r0"
        "p4_c1_r1 p4_c2_r1 p4_c3_r1"
        "p4_c1_r2 p4_c2_r2 p4_c3_r2"
        "p4_c1_r3  p4_c1_r3  p4_c1_r3"
        "p4_c1_r4  p4_c1_r4  p4_c1_r4"
        "p4_c1_r5  p4_c1_r5  p4_c1_r5"
        "footer footer footer";
    grid-gap: 1rem;
    padding: 20px 20px 0px 20px;
    grid-area: contentBox_p4;
    margin-left: 120px;
    margin-bottom: 20px;
}

.label_p4 {
    margin-block-end: 0px;
    color: #003550;
    font-size: 25px;
    margin-left: 10px;
    margin-bottom: 10px;
    display: inline-flex;
}

.footer_2 {
    margin-bottom: 0px;
}

.selector {
    width: 100%;
    max-width: 470px;
    padding: 9px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 25px;
    margin-bottom: 10px;
}

.inp_p3_1 {
    width: 100%;
    max-width: 470px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 25px;
    margin-bottom: 10px;
}
.p4_c1_r0 {
    grid-area: p4_c1_r0;
}
p4_c2_r0 {
    grid-area: p4_c2_r0;
}
p4_c3_r0 {
    grid-area: p4_c3_r0;
}
.p4_c1_r1 {
    /*    background-color: aqua;*/
    grid-area: p4_c1_r1;
}

.p4_c2_r1 {
    /*background-color: darkcyan;*/
    grid-area: p4_c2_r1;
}

.p4_c3_r1 {
    /* background-color: brown;*/
    grid-area: p4_c3_r1;
}

.p4_c1_r2 {
    /*  background-color: antiquewhite;*/
    grid-area: p4_c1_r2;
}

.p4_c2_r2 {
    /* background-color: darkkhaki;*/
    grid-area: p4_c2_r2;
}

.p4_c3_r2 {
    /*  background-color: darkmagenta;*/
    grid-area: p4_c3_r2;
}

.p4_c1_r3 {
    /*  background-color: darkgreen;*/
    display: table-cell;
    grid-area: p4_c1_r3;
}

.p4_c1_r4 {
    /* background-color: darkslateblue;*/
    grid-area: p4_c1_r4;
}

.p4_c1_r5 {
    /* background-color: darksalmon;*/
    grid-area: p4_c1_r5;
}

.footer {
    /* background-color: firebrick;*/
    grid-area: footer;
}

.title_div {
    grid-area: title_div;
    color: #003550;
    font-size: 40px;
    margin-left: 120px;
}

.wrapper_p4 {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 0.08fr 0.07fr 1fr;
    grid-template-areas:
        "baner_p4"
        "title_div"
        "contentBox_p4";
    grid-column-gap: 2rem;
   
}

.p4_button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: white;
    font-size: 25px;
    font-weight: bold;
}

.minDivider {
    font-size: 30px;
    margin-right: 15px;
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 3px solid #003550;
    padding-bottom: 5px;
}

.p4_mini_label {
    color: #003550;
    font-size: 20px;
    margin-left: 60px;
}

.grouping {
    display: inline-block;
    text-align: center;
}

.p4_button_under_label {
    text-align: center;
    font-size: 18px;
    color: #003550;
}

.circle_button {
    border-radius: 50%;
    background-size: cover;
    width: 80px;
    height: 80px;
    margin-left: 35px;
    margin-right: 35px;
    border: 2px solid black;
    z-index:100;
}
.active_button {
    border: 7px solid black;
    border-color: #9af263;
    z-index: 101;
}

.wood {
    background-image: url("/images/Wood3_Icon.png");
}

.brick {
    background-image: url("/images/Brick_Icon.png");
}

.vinyl {
    background-image: url("/images/Vinyl2_Icon.png");
}

.stucco {
    background-image: url("/images/Stucco_Icon.png");
}

.brick_veneer {
    background-image: url("/images/Brick_Veneer3_Icon.png");
}

.fiber_cement {
    background-image: url("/images/Fiber_Cement_Icon.png");
}

.metal {
    background-image: url("/images/Metal2_Icon.png");
}

.other {
    background-image: url("/images/Other_Icon.png");
}

.metal_construction {
    background-image: url("/images/metal_construction.png");
}

.asbestos {
    background-image: url("/images/Asbestos2_Icon.png");
}

.asphalt {
    background-image: url("/images/Asphalt_Icon.png");
}

.tar_gravel {
    background-image: url("/images/Tar_Gravel_Icon.png");
}
.rubber {
    background-image: url("/images/Rubber_Icon.png");
}

.slate {
    background-image: url("/images/Slate_Icon.png");
}

.tile {
    background-image: url("/images/Tile_Icon.png");
}

.wood_shingle {
    background-image: url("/images/Wood_Shingle_Icon.png");
}



.circle_button:hover {
    border: 3px solid black;
    border-color: #2196F3;
}

.footer_p4_1 {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #003550;
    margin-block-end: 0px;
    padding-top: 20px;
}
.footer_p4_1_5 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
    margin-block-end: 0px;
}
    .footer_p4_1_5 a {
        color: #286584;
    }

.footer_p4_2 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
    margin-bottom: 0px;
}

.baner_normal {
    text-align: center;
    color: #003550;
    font-size: 24px;
    margin-bottom: 0px;
}

.baner_small {
    color: black;
    font-size: 10px;
    color: #003550;
    text-align: center;
    line-height: 5%;
    font-weight: bold;
}

.baner_big {
    display: block;
    color: #003550;
    font-size: 75px;
    font-weight: bold;
    text-align: center;
    line-height: 80%;
    margin-bottom: 0px;
}

.baner_bold {
    display: inline;
    color: #003550;
    font-weight: bold;
    font-size: 30px;
}

.baner_normal_b {
    color: #E8EAF9;
    font-size: 18px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-top: 15px;
}

.baner_small_b {
    font-size: 17px;
    color: #E8EAF9;
    margin-left: 15px;
}

.baner_bold_b {
    color: #E8EAF9;
    font-weight: bold;
    font-size: 25px;
}
.propertyinfo_wrapper {
    position: absolute;
    left: 328px;
    width: 80%;
}
.contentBox_p4 {
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.title_div {
    text-align: center;
    margin-right: 850px;
    white-space: nowrap;
}
@media only screen and (max-width:1785px) {
    .info_icon {
        display: none;
    }
}
@media only screen and (max-width:1500px) {
    .contentBox_p4 {
        max-width: 1000px;
    }
    .title_div {
        margin-right: 750px;
    }
}
@media only screen and (max-width:1200px) {

    .wrapper {
        width: 100%;
    }

    .info_icon {
        display: none;
    }

    .contentBox_p4 {
        max-width: 800px;
    }

    .baner_p4 {
        margin-left: 0px;
    }

    .title_div {
        margin-right: 550px;
    }
}
@media only screen and (max-width:1024px) {
    .propertyinfo_wrapper {
        position: unset;
        width: 100%;
    }
}
@media only screen and (max-width:992px) {

    .baner_normal {
        font-size: 20px;
    }

    .info_icon {
        display: none;
    }

    .baner_small {
        margin-top: 15px;
        font-size: 7px;
    }

    .baner_big {
        font-size: 75px;
    }

    .baner_bold {
        font-size: 22px;
    }

    .baner_normal_b {
        font-size: 18px;
    }

    .baner_small_b {
        font-size: 14px;
    }

    .baner_bold_b {
        font-size: 22px;
    }

    .label_p4 {
        font-size: 22px;
    }
}



    @media only screen and (max-width:758px) {

        .wrapper_p4 {
            display: grid;
            justify-content: center;
            padding-top: 80px;
            grid-template-columns: 1fr;
            grid-template-rows: 0.03fr 1fr;
            grid-template-areas:
                "title_div"
                "contentBox_p4";
            grid-column-gap: 0.0rem;
        }

        .btn_p3 {
            width: 100%;
            margin-right: 0;
        }


        .circle_button {
            margin-left: 20%;
        }

        .label_p4 {
            font-size: 25px;
        }

        .contentBox_p4 {
            display: grid;
            min-height: 600px;
            grid-template-columns: 1fr;
            grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.001fr;
            box-shadow: 0px 0px 10px 2px grey;
            border-radius: 15px;
            grid-template-areas:
                "p4_c1_r0"
                "p4_c1_r1"
                "p4_c2_r1"
                "p4_c3_r1"
                "p4_c3_r2"
                "p4_c1_r2"
                "p4_c2_r2"
                "p4_c1_r3"
                "p4_c1_r4"
                "p4_c1_r5"
                "footer";
            grid-gap: 0;
            padding: 20px 20px 20px 20px;
            grid-area: contentBox_p4;
        }

        .title_div {
            font-size: 27px;
            margin-top: 15px;
            margin-right: 0;
            margin-left: 0;
            text-align: left;
        }

        .grouping {
            display: inline-block;
            text-align: center;
            align-content: center;
        }

        .p4_button_under_label {
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            margin-left: 10px;
            color: #003550;
        }

        .brick_button {
            margin-left: 20px;
            margin-right: 5px;
            border: 3px solid black;
        }

        .mobile_br {
            display: none;
        }

        .btn_p4 {
            width: 60%;
            margin: 0 auto;
            margin-top: 20px;
        }

        .btn_p4_2 {
            margin: 0 auto;
            left: 0;
            margin-top: 20px;
            margin-bottom:20px;
        }
        .home_value{
            display: block;
        }
    }
@media only screen and (max-width:395px) {
    .contentBox_p4 {
        padding: 20px 10px 20px 15px;
    }
    .label_p4 {
        margin-left: 0;
        font-size: 22px;
    }
}