.btn_cnt {
    width: 100%;
    height: 70px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 25px;
    position: relative;
}

    .btn_p2:hover {
        background-color: #5264be;
        color: white;
    }

.btn_back {
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 25px;
    position: relative;
    width: 160px;
    height: 65px;
    float: left;
    margin-right: 20px;
    margin-left: 0;
}

    .btn_p2_2:hover {
        background-color: #5264be;
        color: white;
    }


.loading {
    display: flex;
    justify-content: center;
}

.loading_icon {
    margin-top: 200px;

    width: 50px;
    height: 50px;
}

@media only screen and (max-width:758px) {


    .loading_icon {
        margin-top: 200px;
        width: 80px;
        height: 80px;
    }

}