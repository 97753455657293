.page_8_title {
    font-size: 40px;
    padding-top: 120px;
    color: #003550;
}

.mobile_space {
    display: none;
}

.page_8_text {
    font-size: 20px;
    color: #003550;
}

.page_8_text_2 {
    font-size: 25px;
    color: #003550;
    font-weight: 400;
    margin: 0;
    display: inline;
}

.page_8_Divider {
    display: block;
    width: 100%;
    height: 5px;
    margin: 20px auto;
    border-bottom: 3px solid #003550;
    margin-block: 0 auto;
}

.radio_button {
    margin-left: 45px;
    width: 20px;
    height: 20px;
}

.page_8_input {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    border-radius: 13px;
    font-size: 25px;
    margin-bottom: 0;
    height: 45px;
    border-width: thin;
    padding: 9px 20px;
}

.page_8_padd {
    margin-top: 22px;
}

/*.page_8_button {
    display: block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: right;
    margin-right: 10px;
}
.page_8_button:hover {
    background-color: #002E5D;
    color: white;
}

.page_8_button_2 {
    display: block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: left;
    margin-right: 10px;
}
    .page_8_button_2:hover {
        background-color: #002E5D;
        color: white;
    }*/
.page_8_button_wrapper {
    position: relative;
    text-align: center;
}

.page_8_button {
    width: 230px;
    height: 70px;
    font-size: 25px;
    background-color: #003550;
    color: white;
    border-radius: 10px;
    text-decoration: none;
}

    .page_8_button:hover {
        background-color: #286584;
        color: white;
        text-decoration: none;
        border: 0;
    }

.page_8_button_2 {
    position: absolute;
    left: 20px;
    bottom: 0;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-decoration: underline;
}

    .page_8_button_2 span {
        text-decoration: underline;
    }
.page_8_box {
    display: grid;
    max-width: 100%;
    height: 950px;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    margin-left: 150px;
    align-content: center;
    margin: 0px auto;
    grid-template-columns: 3fr 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas:
        "p8_r1 p8_r1 p8_r1"
        "p8_r2_c2 p8_r2_c2 p8_r2_c2"
        "p8_r2_c1 p8_r2_c1 p8_r2_c1"
        "p8_r3  p8_r3  p8_r3"
        "p8_r4_c1  p8_r4_c1  p8_r4_c1"
        "p8_r5  p8_r5  p8_r5";
    grid-gap: 1rem;
    padding: 20px 20px 5px 20px;
    grid-area: contentBox_p4;
    margin-left: 160px;
}

.p8_r1 {
    /*    background-color: aqua;*/
    grid-area: p8_r1;
}

.p8_r2_c1 {
    /*    background-color: aqua;*/
    grid-area: p8_r2_c1;
    display: grid;
}

.p8_r2_c2 {
    /*    background-color: aqua;*/
    grid-area: p8_r2_c2;
    display: grid;
}

.p8_r2_c3 {
    /*    background-color: aqua;*/
    grid-area: p8_r2_c3;
}

.p8_r3 {
    /*    background-color: aqua;*/
    grid-area: p8_r3;
}

.p8_r4_c1 {
    /*    background-color: aqua;*/
    grid-area: p8_r4_c1;
}

.p8_r4_c2 {
    /*    background-color: aqua;*/
    grid-area: p8_r4_c2;
}

.p8_r5 {
    /*    background-color: aqua;*/
    grid-area: p8_r5;
}


.footer_p7_1 {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #003550;
    margin-block-end: 0px;
    padding-top: 90px;
}

.footer_p7_1_5 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
    margin-block-end: 0px;
}
    .footer_p7_1_5 a {
        color: #286584;
    }

.footer_p7_2 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
}
.homeinspection_box_wrapper {
    position: absolute;
    left: 328px;
    width: 80%;
}
.page_8_box {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.page_8_title {
    text-align: center;
    margin-right: 140px;
    white-space: nowrap;
}
@media only screen and (max-width:1200px) {
/*    .page_8_box {
        margin-left: 0;
    }

    .page_8_title {
        margin-left: 0;
    }*/
}

@media only screen and (max-width:1024px) {
    .page_8_box {
        height: 50%;
    }
    .homeinspection_box_wrapper {
        position: unset;
        width: 100%;
    }
}
@media only screen and (max-width:770px) {

    .page_8_box {
        grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr;
        height: 50%;
        padding-top: 5px;
    }

    .page_8_button {
        width: 60%;
        margin: 0;
        margin-bottom: 25px;
    }

    .page_8_button_2 {
        left: 0;
        margin: 0;
        margin-bottom: 25px;
    }

    .mobile_space {
        display: block;
    }

    .radio_button {
        margin: 0;
        margin-top: 20px;
        margin-left:10px;
    }
    .radio_break{
        display:block;
    }
    .radio_button_text{
        margin-left:10px;
        font-size:25px;
    }
    .page_8_title {
        text-align: left;
        white-space: normal;
        width: 100%;
    }
}

@media only screen and (max-width:430px){
    .page_8_box {
        grid-template-areas:
            "p8_r1 p8_r1 p8_r1"
            "p8_r2_c1 p8_r2_c1 p8_r2_c1"
            "p8_r2_c2 p8_r2_c2 p8_r2_c2"
            "p8_r3  p8_r3  p8_r3"
            "p8_r4_c1  p8_r4_c1  p8_r4_c1"
            "p8_r5  p8_r5  p8_r5";
    }
    .page_8_button{
        margin-left: 10px;
    }
}
