
.baner_icon {
    display: initial;
    width: 25px;
    height: 25px;
    background-image: url("/images/tooltip_icon.png");
    background-size: cover;
    background-position: center;
    position: relative;
    display: inline-flex;
    margin-left: 5px;
    padding-top: 10px;
    top: 2px;
}
.banner_p1_text_wrapper{
    margin-right: 40px;
    white-space: nowrap;
}
.banner_box {
    border-radius: 15px;
    margin-top: 10px;
    background-size: cover;
    grid-area: baner_p4;
    display: grid;
    grid-template-columns: 2.8fr 1.3fr;
    grid-template-rows: auto;
    grid-template-areas:
        "baner_header baner_header"
        "baner_p1 baner_p1"
        "baner_p2 baner_p2"
        "baner_p3 baner_p4"
        "baner_p5 baner_p5"
        "baner_sub_header baner_sub_header"
        "baner_footer baner_footer";
    grid-gap: 0;
    margin-left: 120px;
}

.baner_header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    grid-area: baner_header;
    text-align: left;
}


.baner_p1 {
    color: white;
    background-color: #003550;
    grid-area: baner_p1;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.banner_p1_text{
    font-size: 28px;
    font-weight: bold;
}
.baner_p2 {
    color: white;
    background-color: #003550;
    grid-area: baner_p2;
    padding-top: 0;
    padding-bottom: 5px;
    text-align: start;
    padding-right: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.baner_p3 {
    padding-left: 48px;
    border-left: 2px solid black;
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
    grid-area: baner_p3;
}
.baner_p4 {
    text-align: center;
    padding-right: 50px;
    border-right: 2px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
    grid-area: baner_p4;
}
.baner_p5 {
    grid-area: baner_p5;
    border-right: 2px solid black;
    border-left: 2px solid black;
    text-align: center;
    padding: 0 48px 5px 48px;
}
    .baner_p5 p {
        margin: 0;
        color: #003550;
    }

.baner_sub_header {
    color: white;
    grid-area: baner_sub_header;
    text-align: center;
}

.baner_footer {
    color: black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    text-align: center;
    grid-area: baner_footer;
}


.title {
    color: #003550;
    font-size: 40px;
    padding: 0;
    margin-bottom: 10px;
    margin-left: 0;
}

.banner_sub_title {
    color: white;
    background-color: #003550;
    font-size: 27px;
    margin: 0;
    font-weight: bold;
}

.footer_content {
    padding: 5px 48px 5px 48px;
    margin-bottom: 1px;
    font-size: 16px;
    color: #003550;
}


.text_small {
    font-size: 12px;
    font-weight: 500;
}

.text_small_2 {
    color: #003550;
    font-size: 9px;
}

.text_small_3 {
    color: #003550;
    font-size: 14px;
}

.text_small_4 {
    position: relative;
    color: white;
    font-weight: bold;
/*    bottom: 7px;
    font-size: 16px;*/
}

.price_text_1 {
    color: white;
    font-size: 27px;
    font-weight: bold;
    white-space: nowrap;
}

.price_text_2 {
    color: #003550;
    font-size: 27px;
    font-weight: bold;
}

.price_text_3 {
    color: white;
    font-size: 27px;
    font-weight: bold;
}

.text_normal {
    color: white;
    font-size: 27px;
    margin-bottom: 0;
    font-weight: bold;
}
.text_color {
    color: #003550;
    font-size: 27px;
    margin-bottom: 0;
    font-weight: bold;
}



.baner_icon_info {
    background-size: cover;
    background-position: center;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    max-width: 1000px;
    z-index:1;
}

    .baner_icon_info::before,
    .baner_icon_info::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-65.5%) translateY(36%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 1000px;
        background: var(--tooltip-color);
    }

    .baner_icon_info:hover::before,
    .baner_icon_info:hover::after {
        --scale: 1;
    }

    .baner_icon_info::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

.baner_icon_info2 {
    background-size: cover;
    background-position: center;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    max-width: 1000px;
    z-index: 1;
}

    .baner_icon_info2::before,
    .baner_icon_info2::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-62%) translateY(80%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info2::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 1000px;
        background: var(--tooltip-color);
    }

    .baner_icon_info2:hover::before,
    .baner_icon_info2:hover::after {
        --scale: 1;
    }

    .baner_icon_info2::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

.baner_icon_info_home {
    background-size: cover;
    background-position: center;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    max-width: 1000px;
    z-index: 1;
}

    .baner_icon_info_home::before,
    .baner_icon_info_home::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-85.5%) translateY(26%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info_home::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 1000px;
        background: var(--tooltip-color);
    }

    .baner_icon_info_home:hover::before,
    .baner_icon_info_home:hover::after {
        --scale: 1;
    }

    .baner_icon_info_home::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

@media only screen and (max-width: 1200px) {


    .baner_icon_info::before,
    .baner_icon_info::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-69.5%) translateY(22%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 800px;
        background: var(--tooltip-color);
    }

    .baner_icon_info:hover::before,
    .baner_icon_info:hover::after {
        --scale: 1;
    }

    .baner_icon_info::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info2::before,
    .baner_icon_info2::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-71%) translateY(50%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info2::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 800px;
        background: var(--tooltip-color);
    }

    .baner_icon_info2:hover::before,
    .baner_icon_info2:hover::after {
        --scale: 1;
    }

    .baner_icon_info2::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info_home::before,
    .baner_icon_info_home::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-97.5%) translateY(22%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info_home::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 800px;
        background: var(--tooltip-color);
    }

    .baner_icon_info_home:hover::before,
    .baner_icon_info_home:hover::after {
        --scale: 1;
    }

    .baner_icon_info_home::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
}


@media only screen and (max-width: 992px) {
    .banner_sub_title{
        height: 100%;
    }
    .banner_box {
        margin-left: 0;
        height: 100%;
    }

    .text_normal {
        font-size: 24px;
        margin-left: 20px;
    }
    .text_color {
        font-size: 24px;
        margin-left: 20px;
        white-space: nowrap;
    }

    .price_text_1 {
        font-size: 24px;
    }

    .price_text_2 {
        font-size: 24px;
    }

    .price_text_3 {
        font-size: 20px;
    }

    .baner_icon_info::before,
    .baner_icon_info::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-72.5%) translateY(20%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 700px;
        background: var(--tooltip-color);
    }

    .baner_icon_info:hover::before,
    .baner_icon_info:hover::after {
        --scale: 1;
    }

    .baner_icon_info::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info2::before,
    .baner_icon_info2::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-66.5%) translateY(50%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info2::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 700px;
        background: var(--tooltip-color);
    }

    .baner_icon_info2:hover::before,
    .baner_icon_info2:hover::after {
        --scale: 1;
    }

    .baner_icon_info2::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info_home::before,
    .baner_icon_info_home::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-95.5%) translateY(20%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info_home::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: max-content;
        max-width: 700px;
        background: var(--tooltip-color);
    }

    .baner_icon_info_home:hover::before,
    .baner_icon_info_home:hover::after {
        --scale: 1;
    }

    .baner_icon_info_home::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
}

@media only screen and (max-width: 764px) {

    .text_normal {
        font-size: 2.2vw;
        margin-left: 15px;
    }
    .text_color {
        font-size: 2.2vw;
        margin-left: 15px;
    }
    .price_text_1 {
        font-size: 2.2vw;
    }

    .price_text_2 {
        font-size: 2.2vw;
    }

    .price_text_3 {
        font-size: 2.2vw;
    }

    .baner_icon_info {
        font-size: 12px;
    }
    .baner_icon_info2 {
        font-size: 12px;
    }
    .baner_icon_info_home{
        font-size: 12px;
    }
    .footer_content{
        font-size:12px;
    }
    .baner_p1 {
        padding-left: 12px;
    }
    .baner_p3 {
        padding-left: 42px;
    }
    .baner_p5 {
        padding-left: 48px;
        padding-right: 48px;
    }
        .baner_p5 p {
            font-size: 12px;
        }

        .baner_icon_info::before,
        .baner_icon_info::after {
            transform: translateX(-80%) translateY(41%) scale(var(--scale));
        }

        .baner_icon_info::before {
            max-width: 500px;
        }

        .baner_icon_info::after {
            --translate-y: calc(-1 * var(--arrow-size));
            transform: translateX(-50%) translateY(1%) scale(var(--scale));
            content: '';
            border: var(--arrow-size) solid transparent;
            border-bottom-color: var(--tooltip-color);
            transform-origin: bottom center;
        }


    .baner_icon_info2::before,
    .baner_icon_info2::after {
        transform: translateX(-76%) translateY(70%) scale(var(--scale));
    }

    .baner_icon_info2::before {
        max-width: 500px;
    }

    .baner_icon_info2::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info_home::before,
    .baner_icon_info_home::after {
        transform: translateX(-72%) translateY(36%) scale(var(--scale));
    }

    .baner_icon_info_home::before {
        max-width: 500px;
    }

    .baner_icon_info_home::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
}

@media only screen and (max-width: 500px) {

    .baner_icon_info::before,
    .baner_icon_info::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-85%) translateY(42%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 450px;
        background: var(--tooltip-color);
    }

    .baner_icon_info:hover::before,
    .baner_icon_info:hover::after {
        --scale: 1;
    }

    .baner_icon_info::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info2::before,
    .baner_icon_info2::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-78%) translateY(75%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info2::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 450px;
        background: var(--tooltip-color);
    }

    .baner_icon_info2:hover::before,
    .baner_icon_info2:hover::after {
        --scale: 1;
    }

    .baner_icon_info2::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info_home::before,
    .baner_icon_info_home::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-78%) translateY(30%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info_home::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 450px;
        background: var(--tooltip-color);
    }

    .baner_icon_info_home:hover::before,
    .baner_icon_info_home:hover::after {
        --scale: 1;
    }

    .baner_icon_info_home::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
    .baner_p4 {
        padding-right: 10px;
        padding-left: 5px;
    }
}

@media only screen and (max-width: 445px) {
    .baner_icon{
        top: 0;
    }

    .banner_sub_title {
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .baner_sub_header{
        height: 100%;
    }
    .banner_box {
        grid-template-rows: 0.1fr 0.5fr 0.1fr 0.1fr;
    }

    .text_normal {
        position: relative;
        font-size: 3.2vw;
        margin-left: 5px;
        margin:0;
    }

    .text_color {
        position: relative;
        font-size: 3.2vw;
        margin-left: 5px;
        margin: 0;
    }


    .text_small_3 {
        font-size: 2.2vw;
        position: relative;
        margin-right: 6px;
    }

    .price_text_1 {
        position: relative;
        font-size: 3.2vw;
    }

    .price_text_2 {
        position: relative;
        font-size: 3.2vw;
    }

    .price_text_3 {
        position: relative;
        font-size: 3.2vw;
    }

    .footer_content {
        font-size: 10px;
    }

    .title {
        font-size: 25px;
    }


    .text_small_4 {
        bottom: 0px;
        font-size: 3.2vw;
    }

    .baner_icon_info::before,
    .baner_icon_info::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-78%) translateY(35%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 370px;
        background: var(--tooltip-color);
    }

    .baner_icon_info:hover::before,
    .baner_icon_info:hover::after {
        --scale: 1;
    }

    .baner_icon_info::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info2::before,
    .baner_icon_info2::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-83%) translateY(76%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info2::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 370px;
        background: var(--tooltip-color);
    }

    .baner_icon_info2:hover::before,
    .baner_icon_info2:hover::after {
        --scale: 1;
    }

    .baner_icon_info2::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info_home::before,
    .baner_icon_info_home::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-94%) translateY(27%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info_home::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 370px;
        background: var(--tooltip-color);
    }

    .baner_icon_info_home:hover::before,
    .baner_icon_info_home:hover::after {
        --scale: 1;
    }

    .baner_icon_info_home::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
}


@media only screen and (max-width: 384px) {


    .baner_icon {
        display: initial;
        width: 20px;
        height: 20px;
        background-image: url("/images/tooltip_icon.png");
        background-size: cover;
        background-position: center;
        position: relative;
        display: inline-flex;
        margin-left: 5px;
        padding-top: 10px;
        top: 0;
    }

   
    .title {
        font-size: 20px;
    }

    .banner_sub_title {
        font-size: 15px;
        text-align:center;
        margin-left:0;
    }

    .baner_sub_header {
        text-align: start;
    }

 

    .baner_icon_info::before,
    .baner_icon_info::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-75%) translateY(30%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 330px;
        background: var(--tooltip-color);
    }

    .baner_icon_info:hover::before,
    .baner_icon_info:hover::after {
        --scale: 1;
    }

    .baner_icon_info::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info2::before,
    .baner_icon_info2::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-89%) translateY(70%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info2::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 330px;
        background: var(--tooltip-color);
    }

    .baner_icon_info2:hover::before,
    .baner_icon_info2:hover::after {
        --scale: 1;
    }

    .baner_icon_info2::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }

    .baner_icon_info_home::before,
    .baner_icon_info_home::after {
        --scale: 0;
        --arrow-size: 20px;
        --tooltip-color: #003550;
        position: absolute;
        left: 50%;
        transform: translateX(-94%) translateY(23%) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: top center;
    }

    .baner_icon_info_home::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: white;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        width: 330px;
        background: var(--tooltip-color);
    }

    .baner_icon_info_home:hover::before,
    .baner_icon_info_home:hover::after {
        --scale: 1;
    }

    .baner_icon_info_home::after {
        --translate-y: calc(-1 * var(--arrow-size));
        transform: translateX(-50%) translateY(1%) scale(var(--scale));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
}

