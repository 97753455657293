

.invalid {
    width: 95%;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 20px;
    border: 1px solid red !important;
}

.valid {
    width: 95%;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 20px;
    border: 1px solid rgb(13, 145, 24) !important;
}

input, textarea {
    outline: none !important;
    box-shadow: none !important;
}

.p3_error_txt {
    color: red;
    display: block;
    margin-left: 5px;
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
    padding-bottom:3px;
}

.p3_title {
    display: block;
    position: relative;
    color: #003550;
    font-size: 40px;
}

/*.btn_p3 {
    display: block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: right;
    margin-right: 10px;
    margin-top: 50px;
}
    .btn_p3:hover {
        background-color: #002E5D;
        color: white;
    }*/
.btn_p3_wrapper {
    position: relative;
    text-align: center;
    margin-top: 20px;
}
.btn_p3 {
    width: 230px;
    height: 70px;
    font-size: 25px;
    background-color: #003550;
    color: white;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
}

    .btn_p3:hover {
        background-color: #286584;
        color: white;
        border: 0;
    }

.btn_p3_2 {
    position: absolute;
    left: 20px;
    bottom: 0;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: black;
}

    .btn_p3_2 span {
        text-decoration: underline;
    }
.btn_p3_3 {
    display: block;
    width: 160px;
    height: 65px;
    font-size: 25px;
    background-color: #3D9BE9;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    float: left;
    margin-right: 10px;
    margin-top: 50px;
}

    .btn_p3_3:hover {
        background-color: #002E5D;
        color: white;
    }

.p3_link {
    display: inline;
    font-size: 18px;
}

    .p3_link:hover {
        color: #5264be;
        cursor: pointer;
    }

.p3_checkbox_message {
    font-size: 20px;
    color: #003550;
    margin-left: 20px;
    vertical-align: +8px;
}

.p3_checkbox {
    padding: 10px;
    margin-top: 25px;
    width: 30px;
    height: 30px;
    margin-left: 20px;
}

.footer_1 {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #003550;
    margin-block-end: 0px;
}

.footer_1_5 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #286584;
    margin-block-end: 0px;
}
    .footer_1_5 a {
        color: #286584;
    }

    .footer_2 {
        display: block;
        text-align: center;
        font-size: 15px;
        color: #003550;
    }

.vDivider {
    width: 98%;
    height: 5px;
    margin: 20px auto;
    border-bottom: 5px solid #003550;
}

.label_p3 {
    display: block;
    margin-block-end: 0px;
    position: relative;
    color: #003550;
    font-size: 25px;
    margin-left: 10px;
    margin-bottom: 25px;
  
}

.p3_subtittle {
    color: #003550;
    font-size: 25px;
    margin-left: 5px;
    margin-top: 10px;
}

.section_1 {
    display: block;
    margin-block-end: 0px;
    position: relative;
    color: #003550;
    font-size: 25px;
    margin-left: 10px;
    margin-bottom: 10px;
    grid-area: section_1;
}

.inp_street {
    width: 100%;
    max-width: 765px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 25px;
    margin-bottom: 10px;
}

.inp_p3_1 {
    width: 100%;
    max-width: 470px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 25px;
    margin-bottom: 10px;
}

.inp_p3_2 {
    width: 100%;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 25px;
    margin-bottom: 10px;
}

.selector {
    width: 100%;
    max-width: 470px;
    padding: 9px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 25px;
    margin-bottom: 10px;
}

.content_box {
    display: grid;
    width: 100%;
    height: 85%;
    grid-template-columns: 1.5fr 0.5fr 0.5fr 1fr;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    grid-template-areas:
        "section_1 section_1 section_1 section_1"
        "section_2_a section_2_b section_2_c section_2_c"
        "section_2_d section_2_d section_2_d section_2_d"
        "section_2_5_a section_2_5_b section_2_5_c section_2_5_c"
        "section_3_a section_3_b section_3_b section_3_b"
        "divider_1 divider_1 divider_1 divider_1"
        "section_4_a section_4_a section_4_a section_4_a"
        "section_5_a section_5_b section_5_b section_5_c"
        "section_7_a section_7_a section_7_a section_7_a"
        "section_8_a section_8_b section_8_b section_8_c"
        "section_10_a section_10_a section_10_a section_10_a"
        "section_11_a section_11_b section_11_b section_11_c"
        "section_12_a section_12_a section_12_a section_12_a"
        "footer footer footer footer";
    grid-column-gap: 5px;
    grid-row-gap: 0;
    grid-template-rows: auto minmax(0,75px);
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
}

.section_2_a {
    /*  background-color: antiquewhite;*/
    grid-area: section_2_a;
}


.section_2_b {
    max-width: 200px;
    grid-row-end: auto;
    /* background-color: royalblue;*/
    grid-area: section_2_b;
}

.section_2_c {
    max-width: 410px;
    grid-area: section_2_c;
}

.section_2_d {
    grid-area: section_2_d;
}

.section_2_5_a {
    /*  background-color: antiquewhite;*/
    grid-area: section_2_5_a;
}

.section_2_5_b {
    max-width: 200px;
    grid-row-end: auto;
    /* background-color: royalblue;*/
    grid-area: section_2_5_b;
}

.section_2_5_c {
    max-width: 410px;
    grid-area: section_2_5_c;
}

.divider_1 {
    /*  background-color: burlywood;*/
    grid-area: divider_1;
}

.section_3_a {
    /* background-color: brown;*/
    grid-area: section_3_a;
}


.section_3_b {
    /* background-color: blueviolet;*/
    grid-area: section_3_b;
}

.section_4_a {
    /* background-color: rebeccapurple;*/
    grid-area: section_4_a;
}

.section_4_b {
    /*  background-color: palevioletred;*/
    grid-area: section_4_b;
}

.section_5_a {
    grid-area: section_5_a;
}

.section_5_b {
    min-width: 200px;
    grid-area: section_5_b;
}

.section_5_c {
    max-width: 250px;
    grid-area: section_5_c;
}

.section_6_a {
    grid-area: section_6_a;
}


.section_7_a {
    grid-area: section_7_a;
}


.section_8_a {
    grid-area: section_8_a;
}

.section_8_b {
    min-width: 200px;
    grid-area: section_8_b;
}

.section_8_c {
    max-width: 250px;
    grid-area: section_8_c;
}

.section_9_a {
    grid-area: section_9_a;
}


.section_10_a {
    grid-area: section_10_a;
}


.section_11_a {
    grid-area: section_11_a;
}

.section_11_b {
    min-width: 200px;
    grid-area: section_11_b;
}

.section_11_c {
    max-width: 250px;
    grid-area: section_11_c;
}

.section_12_a {
    grid-area: section_12_a;
}

.footer {
    /* background-color: cadetblue;*/
    grid-area: footer;
}
.p3_title {
    text-align: center;
    margin-right: 550px;
    white-space: nowrap;
}
.yourinfo_text_wrapper {
    position: absolute;
    left: 328px;
    width: 80%;
}
.yourinfo_form {
    margin: 0 auto;
    width: fit-content;
}
@media only screen and (max-width:1200px) {
    .yourinfo_form {
        padding: 0 15px;
    }
}
@media only screen and (max-width:1024px) {
    .yourinfo_text_wrapper {
        position: unset;
        width: 100%;
    }
    .p3_title {
        text-align: left;
        margin-right: 0;
        margin-left: 15px;
    }
}
@media only screen and (max-width:768px) {
    .title {
        font-size: 30px;
    }

    .inp_p3_2 {
        width: 100%;
        max-width: 470px;
        padding: 6px 20px;
        box-sizing: border-box;
        border-radius: 15px;
        font-size: 25px;
        margin-bottom: 10px;
    }

    .inp_p3_2 {
        width: 100px;
    }

 
    .btn_p3_3{
        margin-top:25px;
    }

}

@media only screen and (max-width:550px) {
    .title {
        font-size: 35px;
    }

    .btn_p3 {
        display: block;
        width: 100%;
        height: 60px;
        font-size: 30px;
        color: white;
        border: none;
        outline: none;
        border-radius: 10px;
        position: relative;
        align-content: center;
        float: none;
    }


    .btn_p3_3 {
        display: block;
        width: 100%;
        height: 60px;
        font-size: 30px;
        color: white;
        border: none;
        outline: none;
        border-radius: 10px;
        position: relative;
        align-content: center;
        float: none;
    }

    .inp_p3_2 {
        width: 100%;
    }

    .p3_error_txt {
        color: none;
    }

    .content_box {
        display: grid;
        width: 100%;
        height: 90%;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
        box-shadow: 0px 0px 0px 0px grey;
        border-radius: 0px;
        grid-template-areas:
            "section_1"
            "section_2_a"
            "section_2_b"
            "section_2_c"
            "section_2_d"
            "section_2_5_a"
            "section_2_5_b"
            "section_2_5_c"
            "section_3_a"
            "section_3_b"
            "divider_1"
            "section_4_a"
            "section_5_a"
            "section_5_b"
            "section_5_c"
            "section_7_a"
            "section_8_a"
            "section_8_b"
            "section_8_c"
            "section_10_a"
            "section_11_a"
            "section_11_b"
            "section_11_c"
            "section_12_a"
            "footer";
        grid-gap: 0.0rem;
        padding: 0px 0px 0px 0px;
        grid-template-rows: auto minmax(0,75px);
    }
    .p3_title {
        font-size: 30px;
    }
}


/*grid-template-areas:
"section_1 section_1 section_1 section_1"
"section_2_a section_2_b section_2_c section_2_c"
"section_2_5_a section_2_5_b section_2_5_c section_2_5_c"
"section_3_a section_3_b section_3_b section_3_b"
"divider_1 divider_1 divider_1 divider_1"
"section_4_a section_4_a section_4_a section_4_a"
"section_5_a section_5_b section_5_b section_5_c"
"section_7_a section_7_a section_7_a section_7_a"
"section_8_a section_8_b section_8_b section_8_c"
"section_10_a section_10_a section_10_a section_10_a"
"section_11_a section_11_b section_11_b section_11_c"
"section_12_a section_12_a section_12_a section_12_a"
"footer footer footer footer";*/
