.search {
    position: relative;

}

    .search span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .search span {
        left: 20px;
        opacity: .5;
        margin-top: 1px;
    }

    .search input {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 70px;
        border-radius: 15px;
        padding: 0 20px 0 45px;
        font-size: 25px;
        outline: none;
        background: white;
    }

@media only screen and (min-width: 768px) {
    .search {
        margin-top: 100px;
        margin-left: 50px;
    }

        .search input {
            width: 600px;
            height: 80px;
        }
}

@media only screen and (min-width: 1024px) {
    .search {
        margin-top: 50px;
        margin-left: 50px;
    }

        .search input {
            width: 590px;
            height: 90px;
        }
}

@media only screen and (min-width: 1200px) {

    .search {
        margin-top: 20px;
        margin-left: 20px;
    }

        .search input {
            width: 730px;
            height: 60px;
        }
}
