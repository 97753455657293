.p6_title {
    display: block;
    color: #003550;
    font-size: 35px;
    padding-top: 10px;
    margin-left: 0px;
    margin-left: 120px;
}
.wrap_p7 {
  
    padding-right: 5px;
    padding-bottom: 5px;
}
.icon_p7 {
    float: right;
    padding-top: 23px;
    padding-right: 10px;
    cursor: pointer;
}
.share{
    display:none;
}
.document {
    margin-top:5px;
    width: 35px;
    height: auto;
    cursor: pointer;
  
}
.p6_label {
    color: #003550;
    font-size: 20px;
    margin-block: 0 auto;
}
.p8_header {
    color: #003550;
    font-size: 28px;
    margin-block: 0 auto;
    margin-left: 120px;
}

.bol {
    font-size: 20px;
    font-weight: bold;
}

.ita {
    font-size: 15px;
    font-style: italic;
}

.small {
    white-space: nowrap;
    font-size: 20px;
}

.big {
    font-size: 65px;
    text-align: center;
}

.med {
    font-size: 32px;
}

.cent {
    text-align: center;
}

.vDivider {
    display: block;
    width: 100%;
    height: 5px;
    margin: 20px auto;
    border-bottom: 3px solid #003550;
    margin-block: 0 auto;
}

.small {
    width: 70%;
}

.thin {
    border-bottom: 1px solid #003550;
}

.white {
    padding-left: 41px;
    padding-top: 5px;
    color: white;
}

.btn_p7 {
    display: inline-block;
    position: relative;
    width: 350px;
    height: 60px;
    font-size: 20px;
    background-color: #003550;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
}


.offset{
    margin-left:70px;
}


.btn_p7:hover {
    background-color: #286584;
    color: white;
}

.wrap {
    text-align: center;
}
.baner {
    padding-bottom: 20px;
    padding-top: 120px;
}


.contentBox_p6 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    grid-template-areas:
        "p6_header p6_header p6_header"
        "p6_c1_r1 p6_c1_r1 p6_c2_r1"
        "p6_c1_r3 p6_c1_r3 p6_c2_r3"
        "p6_c1_r4 p6_c1_r4 p6_c2_r4"
        "p6_c1_r5 p6_c1_r5 p6_c2_r5"
        "p6_c1_r6 p6_c1_r6 p6_c2_r6"
        "p6_c1_r7 p6_c1_r7 p6_c2_r7"
        "p6_r8 p6_r8 p6_r8"
        "p6_c1_r9 p6_c1_r9 p6_c2_r9"
        "p6_c1_r10 p6_c1_r10 p6_c2_r10"
        "p6_c1_r11 p6_c1_r11 p6_c2_r11"
        "p6_c1_r12 p6_c1_r12 p6_c1_r12"
        "p6_c1_r13 p6_c1_r13 p6_c2_r13"
        "p6_c1_r14 p6_c1_r14 p6_c2_r14"
        "p6_c1_r15 p6_c1_r15 p6_c1_r15"
        "p6_c1_r16 p6_c2_r16 p6_c2_r16"
        "p6_c1_r17 p6_c2_r17 p6_c2_r17"
        "p6_c1_r18 p6_c2_r18 p6_c2_r18"
        "p6_c1_r19 p6_c2_r19 p6_c2_r19"
        "p6_footer p6_footer p6_footer";
    grid-gap: 0.8rem;
    grid-row-gap: 1rem;
    grid-column-gap: 2rem;
    padding: 15px 20px 20px 20px;
    margin-left: 120px;
    padding-bottom: 3px;
    margin-bottom: 20px;
}

.p6_header {
  
    grid-area: p6_header;
}

.baner_c1 {
    grid-area: baner_c1;
}

.baner_c2 {
    grid-area: baner_c2;
}

.baner_c3 {
    grid-area: baner_c3;
}

.p6_c1_r1 {
    grid-area: p6_c1_r1;
}

.p6_c2_r1 {
    grid-area: p6_c2_r1;
}
.p6_c1_r3 {
    grid-area: p6_c1_r3;
}

.p6_c2_r3 {
    grid-area: p6_c2_r3;
}

.p6_c1_r4 {
    grid-area: p6_c1_r4;
}

.p6_c2_r4 {
    grid-area: p6_c2_r4;
}

.p6_c1_r5 {
    grid-area: p6_c1_r5;
}

.p6_c2_r5 {
    grid-area: p6_c2_r5;
}

.p6_c1_r6 {
    grid-area: p6_c1_r6;
}

.p6_c2_r6 {
    grid-area: p6_c2_r6;
}

.p6_c1_r7 {
    grid-area: p6_c1_r7;
}

.p6_c2_r7 {
    grid-area: p6_c2_r7;
}

.p6_r8 {
    grid-area: p6_r8;
}

.p6_c1_r9 {
    grid-area: p6_c1_r9;
}

.p6_c2_r9 {
    grid-area: p6_c2_r9;
}

.p6_c1_r10 {
    grid-area: p6_c1_r10;
}

.p6_c2_r10 {
    grid-area: p6_c2_r10;
}

.p6_c1_r11 {
    grid-area: p6_c1_r11;
}
.p6_c2_r11 {
    grid-area: p6_c2_r11;
}
.p6_c1_r12 {
    grid-area: p6_c1_r12;
}

.p6_c2_r12 {
    grid-area: p6_c2_r12;
}

.p6_c1_r13 {
    grid-area: p6_c1_r13;
}

.p6_c2_r13 {
    grid-area: p6_c2_r13;
}

.p6_c1_r14 {
    grid-area: p6_c1_r14;
}

.p6_c2_r14 {
    grid-area: p6_c2_r14;
}


.p6_c1_r15 {
    grid-area: p6_c1_r15;
}

.p6_c2_r15 {
    grid-area: p6_c2_r15;
}


.p6_c1_r16 {
    grid-area: p6_c1_r16;
}

.p6_c2_r16 {
    grid-area: p6_c2_r16;
}


.p6_c1_r17 {
    grid-area: p6_c1_r17;
}

.p6_c2_r17 {
    grid-area: p6_c2_r17;
}

.p6_c1_r18 {
    grid-area: p6_c1_r18;
}

.p6_c2_r18 {
    grid-area: p6_c2_r18;
}
.p6_c1_r19 {
    grid-area: p6_c1_r19;
}

.p6_c2_r19 {
    grid-area: p6_c2_r19;
}


.p6_footer {
    grid-area: p6_footer;
    margin-left: 120px;
}


.footer_p8_1 {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #003550;
    margin-block-end: 0px;
  
}

.footer_p8_1_5 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
    margin-block-end: 0px;
}
    .footer_p8_1_5 a {
        color: #286584;
    }

.footer_p8_2 {
    display: block;
    text-align: center;
    font-size: 15px;
    color: #003550;
}
.wrap_p7 {
    position: absolute;
    left: 328px;
    width: 80%;
    padding-right: 20px;
}
.quotesummary_box_wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

@media only screen and (max-width:445px) {
    .share {
        display: inherit;
        position:relative;
        bottom:15px;
    }
    .document {
        display: inherit;
        position: relative;
        bottom: 15px;
    }
}


    @media only screen and (max-width:768px) {
       

        .baner{
            padding-top:90px;
        }
        .contentBox_p6 {
            max-width: 100%;
            padding-top:0px;
            width: 100%;
        }

        .p6_label {
            color: #003550;
            font-size: 15px;
            margin-block: 0 auto;
        }

        .bol {
            font-size: 15px;
            font-weight: bold;
        }

        .ita {
            font-size: 10px;
            font-style: italic;
        }

        .small {
            white-space: nowrap;
            font-size: 15px;
        }

        .big {
            font-size: 55px;
            text-align: center;
        }

        .med {
            font-size: 27px;
        }

        .cent {
            text-align: center;
        }
        .p8_header{
            font-size:17px;
        }
        .p6_footer{
            margin-left: 0;
        }

    }

@media only screen and (max-width:994px) {
    .p6_r1{
        display:none;
    }
}


@media only screen and (max-width:1024px) {
    .btn_p7 {
        width: 100%;
    }
    .p8_header{
        margin-left:0;
        margin-top:20px;
    }

    .print{
        display:none;
    }


    .p6_title {
        font-size:25px;
        margin-left:5px;
    }

    .contentBox_p6 {
        margin-left: 0;
    }
    .wrap_p7 {
        position: unset;
        width: 100%;
    }
 
}