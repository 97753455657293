
.save_title {
    font-size: 30px;
    padding-top: 120px;
    margin-left: 160px;
    color: #003550;
}

.save_box {
    display: block;
    width: 800px;
    height: 500px;
    box-shadow: 0px 0px 10px 2px grey;
    border-radius: 15px;
    margin-left: 150px;
    align-content: center;
    padding: 20px;
    margin: 0px auto;
}

.q_numb {
    color: #003550;
    font-size: 17px;
    font-weight: bold;
}

.def_text {
    color: #003550;
    font-size: 17px;
    font-weight: 400;
}

.link {
    color: #003550;
    font-size: 17px;
    font-weight: 400;
    text-decoration: underline;
}

@media only screen and (max-width:770px) {

    .save_title{
        margin-left:0;
    }
    .save_box{
        width:100%;
    }
    .link {
        font-size: 15px;
        display: block;
 
        word-wrap: break-word;
    }
}