.offset{
    padding-bottom:110px;
}
.p1_txt {
    text-align: left;
    color: #003550;
    font-size: 7.2vw;
    padding-left: 10px;
    margin-bottom: 28px
}

.box {
    display: flex;
    flex-direction: column;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:95px;
}
.logo_img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height:auto;
 
 
}

.homeValue {
    position: relative;
    display: inline-flex;
}

    .homeValue input {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 70px;
        border-radius: 15px;
        padding: 0 20px 0 45px;
        font-size: 25px;
        outline: none;
        background: white;
    }

.radio-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    margin-left: 25px;
}

.radio-button-wrapper {
    display: flex;
    margin: 10px;
}

input[type="radio"] {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0.2em;
    background-color: transparent;
    border: 2px solid #003550;
    margin-right: 0.5rem;
    accent-color: #286584;
}

    input[type="radio"]:checked {
        background-color: #003550;
        border-color: #003550;
    }

    input[type="radio"] + label {
        font-size: 1rem;
    }

.checkboxText {
    color: #003550;
}
.text-box-container {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    width: 70%;
}
.text-box-wrapper {
    display: flex;
    flex-direction: column;
}
.text-box_input {
    margin-top: 15px;
    height: 50px;
    border-radius: 15px;
    padding-left: 15px;
}

.p1_subTitte {
    color: #003550;
    font-size: 24px;
    padding-left: 0px;
}
.disabledCursor {
    cursor: default;
}

.activeCursor {
    width: 100%;
    height: 70px;
    display: inline-block;
    position: relative;
    transform: translate(-50%);
    left: 50%;
}
    .activeCursor:hover {
        position: relative;
        transform: translate(-50%);
    }

.btn_page_1 {
    width: 100%;
    height: 70px;
    font-size: 25px;
    background-color: #003550;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    display: inline-block;
    float: none;
    left: 50%;
    margin-top: 5%;
    position: relative;
    transform: translate(-50%);
    cursor: pointer;
}
    .btn_page_1:hover {
        position: relative;
        background-color: #286584;
        color: white;
        transform: translate(-50%);
    }

@media only screen and (min-height: 800px) {
    .btn_page_1 {
        margin-top: 10%;
    }
}
@media only screen and (max-width: 768px) {
    .p1_subTitte {
        padding-left: 0px;
        font-size: 17px;
    }
    .p1_txt {
        padding-left: 0px;
        font-size: 30px;
    }

    .logo_img {
        width: 50%;
        padding-bottom:20px;
    }
    .homeValue{
        margin-top:10px;
    }
}


    @media only screen and (min-width: 768px) {

        .p1_txt {
            text-align: left;
            font-size: 45px;
            padding-left: 0px;
        }

        .p1_subTitte {
            padding-left: 0px;
            font-size: 24px;
        }

        .box {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            box-shadow: 0px 0px 10px 2px grey;
            border-radius: 15px;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 55%;
        }

        .btn_page_1 {
            margin-top: 10%;
            width: 70%;
        }

        .homeValue {
            margin-top: 100px;
            margin-left: 50px;
        }

            .homeValue input {
                width: 600px;
                height: 80px;
            }
        .radio-buttons-container {
            margin-left: 65px;
        }

        .text-box-container {
            margin-left: 65px;
        }
    }

    @media only screen and (min-width: 1024px) {

        .p1_txt {
            text-align: left;
            font-size: 45px;
            padding-left: 0px;
        }

        .p1_subTitte {
            padding-left: 0px;
            font-size: 24px;
        }


        .box {
            display: flex;
            flex-direction: column;
            width: 684px;
            box-shadow: 0px 0px 10px 2px grey;
            border-radius: 15px;
        }

        .btn_page_1 {
            width: 550px;
            height: 60px;
            margin-top: 5%;
        }

        .homeValue {
            margin-top: 50px;
            margin-left: 50px;
        }

            .homeValue input {
                width: 590px;
                height: 90px;
            }
        .box_home_wrapper {
            position: absolute;
            left: 328px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
        }

    }

    @media only screen and (min-width: 1200px) {

        .p1_txt {
			text-align: left;
            color: #003550;
            font-size: 40px;
            padding-left: 150px;
        }

        .p1_subTitte {
            color: #003550;
            font-size: 24px;
            padding-left: 150px;
        }


        .box {
            display: flex;
            flex-direction: column;
            width: 810px;
            box-shadow: 0px 0px 10px 2px grey;
            border-radius: 15px;
            margin-left: 150px;
            align-content: center;
            padding: 20px;
            margin: 0px auto;
            margin-bottom: 20px;
        }

        .btn_page_1 {
            width: 230px;
            height: 70px;
            margin: 0;
            position: relative;
            margin-top: 60px;
            transform: translate(-50%);
        }

        .homeValue {
            margin-top: 20px;
            margin-left: 20px;
        }

            .homeValue input {
                width: 730px;
                height: 60px;
            }
    }
