nav {
	position: fixed;
	width: 100%;
	height: 90px;
	background-color: #003550;
	margin-bottom: 50px;
	z-index: 998;
	/*  margin-bottom: 10px;*/
}
.save_link {
	position: fixed;
	top: 8px;
	right: 16px;
	z-index: 999;
	color: white;
	font-size: 22px;
}
.download_icon {
	position: fixed;
	top: 11px;
	right: 155px;
	display: block;
	color: #003550;
	min-width: 30px;
	height: 28px;
	font-size: 40vmin;
	margin-bottom: 30px;
	z-index: 999;
}
.logo_icon {
	margin-top: 7px;
	margin-left: 10px;
	width: auto;
	height: 75px;
}

@media only screen and (max-width: 768px) {
	#TopBar {
		display: flex;
		justify-content: center;
	}

	.save_link {
		position: fixed;
		top: 8px;
		right: 16px;
		z-index: 999;
		color: white;
		font-size: 17px;
	}

	.download_icon {
		position: fixed;
		top: 11px;
		right: 120px;
		display: block;
		color: #003550;
		min-width: 20px;
		height: 20px;
		font-size: 4vmin;
		margin-bottom: 30px;
		z-index: 999;
	}

	.logo_icon {
		margin-top: 15px;
		margin-left: 5px;
		width: auto;
		height: 58px;
	}
}
